/* You can add global styles to this file, and also import other style files */
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~ngx-bootstrap/datepicker/bs-datepicker.css";
@import "../node_modules/@fortawesome/fontawesome-pro/css/all.css";
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "~@ng-select/ng-select/themes/default.theme.css";

@font-face {
    font-family: circular;
    src: url('/assets/font/CircularStd-Medium.eot?#iefix') format('embedded-opentype'),
         url('/assets/font/CircularStd-Medium.otf') format("opentype");
  }

body {
    height: 93vh;
}

div {
    color: #666666;
}

h4 {
    color: #808080;
    font-family: 'circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,'Open Sans', 'Helvetica Neue', sans-serif;
}

h5 {
    font-family: 'circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,'Open Sans', 'Helvetica Neue', sans-serif;
}

h6 {
    color: #808080;
    font-family: 'circular', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,'Open Sans', 'Helvetica Neue', sans-serif;
}

a {
    color: #007bff;
    cursor: pointer;
}

a:hover {
    color: #007bff;
}

.alert-dismissible {
  padding-right: 40px;
}

.alt-link {
    color: #808080;
}
.alt-link:hover {
    color: #666666;
}


.text-center {
    text-align: center;
    margin: 0 auto;
}

.btn-primary {
    background-color: #00afef;
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
}

.btn-primary.disabled, .btn-primary:disabled {
  background-color: #00afef;
  border-color: #00afef;
}

.btn-secondary {
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
}

.btn-primary:hover:not([disabled]) {
    background-color: #2491bf;
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
}

.btn-danger {
    border: none;
    padding-top: 7px;
    padding-bottom: 7px;
}

.btn-warning {
    border: none;
}

.btn-success{
    border: none;
}

.btn-dropdown{
    border: none;
    background-color: transparent;
    color: #808080;
    position: relative;
    vertical-align: top;
}

.btn:focus,.btn:active {
    outline: none !important;
    box-shadow: none;
 }

thead>tr>th {
    cursor: pointer;
}

.small-text {
    font-size: 10px;
    color: #808080;
}

.fa-arrow-circle-right:before,
.fa-arrow-circle-left:before{
    color: rgba(0, 0, 0, 0.6);
}

div.card.hover:active,
div.card.hover:focus,
div.card.hover:hover{
    background-color: #f5f5f5;
    cursor: pointer;
} 

/* ol {
  margin-top: 16px;
} */
ul{
    margin-bottom: 0px;
}

span.fa {
    padding-right: 5px;
    padding-left: 5px;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

a[role='button'] {
    font-size: 1.25rem;
}

div.arrow-container.next::before {
    background-image: linear-gradient(to right, transparent, rgba(0,0,0,0.10))!important
}
div.arrow-container.prev::before {
    background-image: linear-gradient(to left, transparent, rgba(0,0,0,0.10))!important
}

.alert-secondary {
    background-color: #fafafa;
    padding-top: 2px; 
    padding-right: 2px; 
    padding-bottom: 2px;
    border-radius: 6px;
    /* border: 1px solid #666666!important; */
    color: #343a40!important;
    /* height: 50px; */
}

.alert-slim {
    padding: 1px;
    margin: 0;
    font-weight: bold;
}

.alert-secondary>div.form-row>div.col {
    line-height: 38px;
    font-weight: bold;
    color: #666666!important;
}

hr.signature {
    border-top: 1px solid; 
    margin-top: 5px; 
    margin-bottom: 5px
}
.bg-info {
    background-color: rgba(246,255,82,0.5) !important;
}
.bg-primary {
    background-color: #00afef !important;
    color: white;
}

/* Material Checkboxes */
.mat-checkbox-ripple .mat-ripple-element,
.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #00afef !important;
}

/* WIZARD TAB / ACCORDION STYLES */

accordion-group > div.card > div.card-header {
    padding: 0px;
}
accordion-group > div.card.agDisabled > div.card-header {
    background-color: #f7f7f7;
}
tabset > ul > li.nav-item.tabError > a.nav-link {
  background-color: #f8d7da;
}
accordion-group > div.card.agDisabled > div.card-header button > div {
    color: #CCCCCC;
    font-variant: normal;
    font-style: italic;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
}
accordion-group > div.card.agError > div.card-header {
    background-color: #f8d7da;
}
accordion-group > div.card.agError > div.card-header button > div,
accordion-group > div.card.agDefault > div.card-header button > div,
accordion-group > div.card.agFolders > div.card-header button > div {
    font-weight: bold;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
}
accordion-group > div.card.agFolders > div.panel-collapse.collapse.in.show > div.panel-body.card-block.card-body {
  padding: 0;
}
.nav-pills .nav-link {
    background-color: rgba(0,175,239,0.1);
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem;
    border-bottom-left-radius: 0rem;
    border-bottom-right-radius: 0rem;
    border-left: 1px solid rgba(128, 128, 128, 0.3);
    border-right: 1px solid rgba(128, 128, 128, 0.3);
    border-top: 1px solid rgba(128, 128, 128, 0.3);
    color: #2491bf;
}
.nav-pills .nav-link.disabled {
    color: #CCCCCC;
    background-color: #f7f7f7;
}
.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
    background-color: transparent;
    color: #2491bf;
}

@media (min-width: 768px){
  .navbar-expand-md .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 1rem;
  }
}

/* HELP / INFO POP-UP STYLES */
.hint {
    background-color: #e5f7fd;
    color: #2491bf;
    margin-bottom: 1em;
}
.hint-title {
    background-color: #e5f7fd;
    color: #2491bf !important;
}
.popover {
    background-color: #e5f7fd;
    white-space: pre-line;
    max-width: 80%;
  }
.popover-body {
    color: #2491bf;
}
.popover>.arrow::after {
    border-left-color:  #e5f7fd;
    border-right-color:  #e5f7fd;
  }
.popover-title {
    background-color: #e5f7fd;
    color: #00afef;
    border-bottom-color:  #b8daff;
}
.my-drop-zone { 
    border: dotted 3px lightgray; 
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 20px;
    padding-right: 20px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 10px;
}

/* Login Screen */
.forgot-pass {
    font-size: 12px;
    padding-top: 8px;
}

.terms-of-service-label {
    display: block;
    padding-left: 20px;
    text-indent: -22px;
  }
  .terms-of-service-input {
    margin:0;
    vertical-align: middle;
    position: relative;
    top: -1px;
    cursor: pointer;
    min-width: 18px;
    min-height: 18px;
  }

  .dropdown-toggle::after {
    display: none;
  }

  .pointerCursor {
      cursor: pointer;
  }

/* SCREEN & MEDIA QUERIES */
@media screen and (min-width: 0px) and (max-width: 500px) {
    .small-screen { display: none; }  /* show it on small screens with width of 500px or less */
    modal-container { padding-bottom: 75px; }
  }
@media screen and (min-width: 501px) {
    .big-screen {display: none; } /* show it on screens with a width of 501px or greater */
}
.outer { 
    position: relative;
}
.card-underlay, .card-overlay {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    margin: 1px 1px 1px 1px;
  }
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    border-radius: 0.25rem;
    display: inline-block;
    width: 2rem;
  }
  .custom-day:hover, .custom-day.focused {
    background-color: #00afef;
    color: white;
  }
  .holiday {
    background-color: #c9302c;
    border-radius: 1rem;
    color: white;
  }
  
  td.in-range {
    background-color: white !important;
  }

  .today {
    border:1px solid #6c757d!important;
  }
  .day-disabled, .day-disabled:hover {
    border-radius: 0rem !important;
    padding: 0.25rem 0.25rem !important;
    background: #e9ecef;
  }

  .disabledtile {
    pointer-events: none;
    opacity: 0.8;
    background: #e9ecef;
  }

  .deleteButtonBehind {
    float:right;
    width:20%;
    height: 98.5%;
    margin: 1px 1px 1px 0px;
    border: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

  .dropdownButton {
    padding-right: 0px;
    padding-top: 0px;
  }

  .badge-info {
      background-color: #00afef
  }

  .align-middle-v {
    vertical-align: middle;
  }

  .profile-form {
    margin: 2rem auto;
    max-width: 500px;
  }

  .primary-color {
      color: #00afef;
  }

  .btn-outline-primary {
    color: #00afef;
    background-color: transparent;
    background-image: none;
    border-color: #00afef;
}

.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active,
.show>.btn-outline-primary.dropdown-toggle,
.btn-outline-primary:not(:disabled):not(.disabled).active{
    background-color: #00afef;
    border: none;
}

.btn-outline-primary:hover {
    color: #fff;
    background-color: #00afef;
    background-image: none;
    border-color: #00afef;
}

.doc-border {
  border-color: rgba(0, 175, 239, 0.33);
}

.upgrade-header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    color: #721c24;
    background-color: rgba(248,215,218,0.8);
    border-color: #f5c6cb;
    width: 100%  !important;
}

virtual-scroller {
  width: 100%;
  margin-left: 5px;
  margin-right: 5px;
  height: calc(100vh - 260px);
}

/* PRICING TILES */  
  .pricing .card {
    border: none;
    border-radius: 1rem;
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1);
  }
  
  .pricing .fa-ul {
      margin-left: 1.5rem;
  }

  .pricing hr {
    margin-bottom: 1.5rem;
  }
  
  .pricing .card-title {
    margin: 0.5rem 0;
    font-size: 2rem;
    letter-spacing: .1rem;
    font-weight: lighter;
    color: #00afef;
  }
  
  .pricing .card-subtitle {
    margin: 0.5rem 0;
    font-size: 0.9rem;
    font-weight: normal;
  }

  .pricing .card-price {
    color: #00afef;
    font-size: 3.5rem;
    margin: 0;
  }
  
  .pricing .card-price .period {
    font-size: 0.8rem;
  }
  
  .pricing .tax-text {
    font-size: 0.75rem;
  }

  .pricing .card-banner {
    margin-left: -1.25rem;
    margin-right: -1.25rem;
    padding: 0.5rem;
    color: white;
    background-color: #00afef;
    text-align: center;
    font-size: larger;
    font-weight: bold;
    letter-spacing: 0.1rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .pricing ul li {
    margin-bottom: 0.75rem;
    font-size: 1rem;
  }
  
  .pricing .text-muted {
    opacity: 0.7;
  }
  
  .pricing .btn, .pricing .btn-primary:hover:not([disabled]) {
    font-size: 80%;
    border-radius: 5rem;
    letter-spacing: .1rem;
    font-weight: bold;
    padding: 1rem;
    /* opacity: 0.7; */
    transition: all 0.2s;
    margin-top: 1.25rem;
  }

  @media (min-width: 992px) {
    .pricing .card:hover {
      margin-top: -.25rem;
      margin-bottom: .25rem;
      box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
    }
    .pricing .card:hover .btn {
      opacity: 0.8;
    }
  }
  /* END PRICING TILES */  

  .upload-btn {
    transition: all 0.2s;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.2);
  }
  
  .upload-btn:hover {
    margin-top: -.25rem;
    margin-bottom: .25rem;
    box-shadow: 0 0.5rem 1rem 0 rgba(0, 0, 0, 0.3);
  }

  /* NG SELECT STYLES */

  .create-new {
      cursor: pointer;
      padding-top: 5px;
      padding-bottom: 10px
  }

  .ng-dropdown-footer {
      border-top: unset !important;
      padding: 0px 10px !important;
  }

  .ng-dropdown-footer:hover {
      background-color: #f5faff;
  }

  /* ANGULAR MATERIALS TAB STYLES */
  div.mat-ripple,
  div.mat-tab-header-pagination-chevron,
  div.mat-ripple mat-tab-header-pagination mat-tab-header-pagination-after mat-elevation-z4 {
    display: none;
  }
  .mat-tab-group.mat-primary .mat-ink-bar, .mat-tab-nav-bar.mat-primary .mat-ink-bar{
    background: #00afef;
    height: 2px; 
  }
  a.mat-tab-link {
    padding: 0px;
    font-weight: bold;
  }
  .mat-tab-label-active,
  .mat-tab-label-active:active,
  .mat-tab-label-active:focus,
  .mat-tab-label-active:hover,
  .mat-tab-label-active:disabled
  { 
      color: #00afef;
      /* font-weight: bold; */
      text-decoration: none;
  }

  /* END NG SELECT STYLES */
html, body { height: 100%; }
/* body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; } */

/* DRAGGABLE PREVIEW STYLING */
.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
              0 8px 10px 1px rgba(0, 0, 0, 0.14),
              0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.example-custom-placeholder {
  background: #ccc;
  border: dotted 3px #999;
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}
/* END DRAGGABLE PREVIEW STYLING */

.NgxEditor__Wrapper {
  min-height: 200px;
}

@media (hover: none) {
  .rb:not(:active),
  .cb:not(:active) {
    background-color: white;
    color: #00afef;
  }
}

.buttonOriginal {
  background: #dc3545;
  -webkit-transition: background-color .25s ease-out;
  -moz-transition: background-color .25s ease-out;
  -o-transition: background-color .25s ease-out;
  transition: background-color .25s ease-out;
}

.buttonOriginal.buttonClicked {
  background: #00afef;
}

.buttonClicked>.iconOriginal.iconClicked {
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: white;
}

.buttonOriginal>.iconOriginal {
  -moz-transition: all .25s ease-out;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  color: white;
}

.removeTextOriginal {
  opacity: 0%;
  transition:opacity .25s ease-out;
}

.removeTextOriginal.removeTextDisplayed {
  opacity: 100%;
  transition:opacity .25s ease-out;
}

/* REAL CLASSES - UNCOMMENT IF YOU DON'T LIKE FUN @G40 @DBAG */
/* 
.buttonOriginal {
  background: #dc3545;
  -webkit-transition: background-color .25s ease-out;
  -moz-transition: background-color .25s ease-out;
  -o-transition: background-color .25s ease-out;
  transition: background-color .25s ease-out;
}

.buttonOriginal.buttonClicked {
  background: #00afef;
}

.buttonClicked>.iconOriginal.iconClicked {
  -ms-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  color: white;
}

.buttonOriginal>.iconOriginal {
  -moz-transition: all .25s ease-out;
  -webkit-transition: all .25s ease-out;
  transition: all .25s ease-out;
  color: white;
}

.removeTextOriginal {
  opacity: 0%;
  transition:opacity .25s ease-out;
}

.removeTextOriginal.removeTextDisplayed {
  opacity: 100%;
  transition:opacity .25s ease-out;
} */